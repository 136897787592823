<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2" style="text-align: justify">
        <chemical-latex :content="atomName" />
        has 2 commonly occurring isotopes.
        <stemble-latex :content="'$' + abundance1 + '$'" />
        of
        <chemical-latex :content="atomName" />
        atoms have
        <stemble-latex :content="'$' + neutrons1 + '$'" />
        neutrons and
        <stemble-latex :content="'$' + protons1 + '$'" />
        protons, whereas the other
        <stemble-latex :content="'$' + abundance2 + '$'" />
        has
        <stemble-latex :content="'$' + neutrons2 + '$'" />
        neutrons and
        <stemble-latex :content="'$' + protons2 + '$'" />
        protons. Using the isotopic composition provided, and the known masses of protons (
        <latex-number number="1.00727" />
        amu) and neutrons (
        <latex-number number="1.00866" />
        amu), calculate the expected mass of
        <chemical-latex :content="atomName" />
        . Round your answer to 3 decimal places.
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{amu}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question4',
  components: {
    LatexNumber,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    chemicalElement() {
      return this.taskState.getValueBySymbol('element').content;
    },
    atomName() {
      return this.chemicalElement.name;
    },
    isotopes() {
      return this.chemicalElement.isotopes;
    },
    isotope1() {
      return this.isotopes[0];
    },
    neutrons1() {
      return this.isotope1.neutrons;
    },
    protons1() {
      return this.isotope1.atomicNumber;
    },
    isotope2() {
      return this.isotopes[1];
    },
    neutrons2() {
      return this.isotope2.neutrons;
    },
    protons2() {
      return this.isotope2.atomicNumber;
    },
    abundance1() {
      return `\\ce{{${this.isotope1.abundance}}${'\\%'}}`;
    },
    abundance2() {
      return `\\ce{{${this.isotope2.abundance}}${'\\%'}}`;
    },
  },
};
</script>
